$('.header-inner__btn').on('click', function(){
	$(this).toggleClass('header-inner__btn--active');
	$(this).next().fadeToggle();
})

$(window).scroll(function(){
	if($(window).scrollTop() > 50){
		$('.header').addClass('header--bg');
		$('.header-container').addClass('container');
		$('.nav-bot').addClass('nav-bot--after');
		$('.logo').addClass('logo--indent');
		$('.header-inner__top').hide();
		$('.top-line__text').hide();
	}else{
		$('.header').removeClass('header--bg');
		$('.header-container').removeClass('container');
		$('.header-inner__top').show();
		$('.top-line__text').show();
		$('.nav-bot').removeClass('nav-bot--after');
		$('.logo').removeClass('logo--indent');
	}
})