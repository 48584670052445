// footer-list
blockHeight('.footer__col'); 

function blockHeight(block,wWidth){ 
	function addOnload(callback) { 
		if ( "undefined" != typeof(window.attachEvent) ) { 
			return window.attachEvent("onload", callback); 
		} 
		else if ( window.addEventListener ){ 
			return window.addEventListener("load", callback, false); 
		} 
	} 
	var block = $(block); 
	var defaults = 495; 
	if(wWidth){ 
		minWidth = wWidth-17 
	}else{ 
		minWidth = defaults 
	} 
	addOnload(function(){ 
		if($(window).width()>minWidth){ 
			var maxH = 0; 
			block.each(function(){ 
				if($(this).height()>maxH){ 
					maxH = $(this).height() 
				} 
			}) 
			block.height(maxH) 
		}else{ 
			block.css('height','auto') 
		} 
	}) 
	$(window).resize(function(){ 
		if($(window).width()>minWidth){ 
			var maxH = 0; 
			block.each(function(){ 
				$(this).css('height','auto') 
				if($(this).height()>maxH){ 
					maxH = $(this).height() 
				} 
			}) 
			block.height(maxH) 
		}else{ 
			block.css('height','auto') 
		} 
	}) 
}