// collapse-block
function blockHeight2(block,wWidth){
	function addOnload(callback) { 
		if ( "undefined" != typeof(window.attachEvent) ) { 
			return window.attachEvent("onload", callback); 
		} 
		else if ( window.addEventListener ){ 
			return window.addEventListener("load", callback, false); 
		} 
	} 
	var block = block; 
	var defaults = 495; 
	if(wWidth){ 
		minWidth = wWidth-17 
	}else{ 
		minWidth = defaults 
	} 
	if($(window).width()>minWidth){ 
		var maxH = 0; 
		block.each(function(index){ 
			if($(this).height()>maxH){ 
				maxH = $(this).height() 
			}
		}) 
		block.height(maxH) 
	}else{ 
		block.css('height','auto') 
	} 
	$(window).resize(function(){ 
		if($(window).width()>minWidth){ 
			var maxH = 0; 
			block.each(function(){ 
				$(this).css('height','auto') 
				if($(this).height()>maxH){ 
					maxH = $(this).height() 
				} 
			}) 
			block.height(maxH) 
		}else{ 
			block.css('height','auto') 
		} 
	}) 
}

$('.collapse__top').on('click', function(){
	var $this = $(this);
	$(this).toggleClass('collapse__top--active');

	$(this).siblings().fadeToggle(function(){
		blockHeight2($this.siblings().find('.collapse-block__title--top'));
		blockHeight2($this.siblings().find('.collapse-block__title--bot'));
		blockHeight2($this.siblings().find('.collapse-block--top'));
		blockHeight2($this.siblings().find('.collapse-block--bot'));
	});
})